/* Flexbox mixins */
@-ms-viewport {
  width: device-width;
}
.zs-visible-xs,
.zs-visible-sm,
.zs-visible-md,
.zs-visible-lg {
  display: none !important;
}
.zs-visible-xs-block,
.zs-visible-xs-inline,
.zs-visible-xs-inline-block,
.zs-visible-sm-block,
.zs-visible-sm-inline,
.zs-visible-sm-inline-block,
.zs-visible-md-block,
.zs-visible-md-inline,
.zs-visible-md-inline-block,
.zs-visible-lg-block,
.zs-visible-lg-inline,
.zs-visible-lg-inline-block {
  display: none !important;
}
@media (max-width: 47.93em) {
  .zs-visible-xs {
    display: block !important;
  }
  table.zs-visible-xs {
    display: table !important;
  }
  tr.zs-visible-xs {
    display: table-row !important;
  }
  th.zs-visible-xs,
  td.zs-visible-xs {
    display: table-cell !important;
  }
}
@media (max-width: 47.93em) {
  .zs-visible-xs-block {
    display: block !important;
  }
}
@media (max-width: 47.93em) {
  .zs-visible-xs-inline {
    display: inline !important;
  }
}
@media (max-width: 47.93em) {
  .zs-visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 48em) and (max-width: 61.93em) {
  .zs-visible-sm {
    display: block !important;
  }
  table.zs-visible-sm {
    display: table !important;
  }
  tr.zs-visible-sm {
    display: table-row !important;
  }
  th.zs-visible-sm,
  td.zs-visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 48em) and (max-width: 61.93em) {
  .zs-visible-sm-block {
    display: block !important;
  }
}
@media (min-width: 48em) and (max-width: 61.93em) {
  .zs-visible-sm-inline {
    display: inline !important;
  }
}
@media (min-width: 48em) and (max-width: 61.93em) {
  .zs-visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 62em) and (max-width: 74.93em) {
  .zs-visible-md {
    display: block !important;
  }
  table.zs-visible-md {
    display: table !important;
  }
  tr.zs-visible-md {
    display: table-row !important;
  }
  th.zs-visible-md,
  td.zs-visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 62em) and (max-width: 74.93em) {
  .zs-visible-md-block {
    display: block !important;
  }
}
@media (min-width: 62em) and (max-width: 74.93em) {
  .zs-visible-md-inline {
    display: inline !important;
  }
}
@media (min-width: 62em) and (max-width: 74.93em) {
  .zs-visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 75em) {
  .zs-visible-lg {
    display: block !important;
  }
  table.zs-visible-lg {
    display: table !important;
  }
  tr.zs-visible-lg {
    display: table-row !important;
  }
  th.zs-visible-lg,
  td.zs-visible-lg {
    display: table-cell !important;
  }
}
@media (min-width: 75em) {
  .zs-visible-lg-block {
    display: block !important;
  }
}
@media (min-width: 75em) {
  .zs-visible-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 75em) {
  .zs-visible-lg-inline-block {
    display: inline-block !important;
  }
}
@media (max-width: 47.93em) {
  .zs-hidden-xs {
    display: none !important;
  }
}
@media (min-width: 48em) and (max-width: 61.93em) {
  .zs-hidden-sm {
    display: none !important;
  }
}
@media (min-width: 62em) and (max-width: 74.93em) {
  .zs-hidden-md {
    display: none !important;
  }
}
@media (min-width: 75em) {
  .zs-hidden-lg {
    display: none !important;
  }
}
/*
Popup menu

Popup menu

Markup:
<div class="zs-menu">
	<a href="#">Link 1</a>
	<a href="#">link 2 </a>
	<a href="#" class="zs-active">Link 3</a>
	<a href="#">Link 4</a>
</div>

Styleguide Components.PopupMenu
*/
.zs-menu {
  background: #ffffff;
  border-radius: 0.3em;
  border: 1px solid #e1e9ef;
  box-shadow: 0 3px 6px #dee3e3;
  box-sizing: border-box;
  display: inline-block;
  min-width: 150px;
  max-width: 400px;
  z-index: 2000;
}
.zs-menu > a,
.zs-menu > a:visited,
.zs-menu > a:hover,
.zs-menu > li,
.zs-menu > ul > li > a,
.zs-menu > nav > a,
.zs-menu > nav > a:visited {
  color: #53565a;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  .padding(1.42,1.42,1.42,1.42);
}
.zs-menu > a.zs-active,
.zs-menu > a:visited.zs-active,
.zs-menu > a:hover.zs-active,
.zs-menu > li.zs-active,
.zs-menu > ul > li > a.zs-active,
.zs-menu > nav > a.zs-active,
.zs-menu > nav > a:visited.zs-active {
  background: #e5eaef;
}
.zs-menu > a:hover,
.zs-menu > a:visited:hover,
.zs-menu > a:hover:hover,
.zs-menu > li:hover,
.zs-menu > ul > li > a:hover,
.zs-menu > nav > a:hover,
.zs-menu > nav > a:visited:hover {
  background: #f5f7f9;
}
.zs-menu > ali a,
.zs-menu > a:visitedli a,
.zs-menu > a:hoverli a,
.zs-menu > lili a,
.zs-menu > ul > li > ali a,
.zs-menu > nav > ali a,
.zs-menu > nav > a:visitedli a {
  color: #53565a;
  display: block;
  white-space: nowrap;
}
#zs-lbr {
  display: none;
}
.zs-user-bar {
  display: inline-block;
  position: absolute;
  right: 0em;
  margin-right: 2.5em;
  top: 50%;
  margin-top: -1.56714286em;
}
.zs-user-bar > div[role="profile"] > nav {
  min-width: 120%;
}
.zs-user-bar > div {
  position: relative;
  display: inline-block;
  .padding(0,1.065,0,1.065);
  .margin(0,0,0,0.71);
  min-height: 3.13428571em;
  max-height: 3.13428571em;
  height: 3.13428571em;
}
.zs-user-bar > div:first-child {
  .margin(0,0,0,0);
    margin-left: 0;
}
.zs-user-bar > div > a {
  cursor: pointer;
  position: relative;
  .margin(0,0,0,0);
  display: inline;
  line-height: 3.13428571em;
}
.zs-user-bar > div > a > .zs-icon {
  .margin(0,0,0,0);
}
.zs-user-bar > div > a > .zs-icon:before {
  .margin(0,0,0,0);
}
.zs-user-bar > div > a > .zs-icon.zs-icon-large:before {
  .margin(0,0,0,0);
}
.zs-user-bar > div > a > .zs-icon .zs-badge {
  font-size: 0.75em;
  top: 0.75em;
  left: 0.75em;
}
.zs-user-bar > div > a > label {
  line-height: 1.14285714em;
  cursor: pointer;
  .padding(0,0,0,1.42);
}
.zs-user-bar > div > nav {
  min-width: 10em;
  position: absolute;
  right: -0.0625;
  top: 100%;
  border-top-right-radius: 0;
}
.zs-user-bar > div > nav > a {
  z-index: 3000;
}
.zs-user-bar .zs-message-container {
  position: relative;
}
.zs-user-bar .zs-message-container .zs-message.zs-error {
  min-height: 0;
  z-index: 1000;
  position: absolute;
  top: 50%;
  right: 0;
  margin-left: -47em;
  margin-top: -1.35em;
}
.zs-user-bar .zs-menu ul li:nth-child(1) a.lbar-link {
    color: #722f20;
}
.zs-user-bar .zs-menu #lbar-notifications-menu-popup ul {
    list-style: none;
}
.zs-user-bar .zs-menu #lbar-notifications-menu-link {
    display: none;
}
.zs-hide,
.zs-menu.zs-hide {
  display: none;
}
.zs-menu .zs-message {
  border: none;
  min-width: 18em;
}
.zs-menu .zs-message a {
  display: block;
}
.zs-header .zs-brand-bar .zs-logo {
  display: inline-block;
  float: inherit;
  vertical-align: middle;
}
.zs-header .zs-brand-bar .zs-brand-name {
  display: inline-block;
  float: inherit;
  line-height: 100%;
  vertical-align: middle;
}
.zs-app-message {
  .margin(0,0,0,25);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  font-size: 0.875em;
  background: #fffbeb;
  display: block;
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
  position: relative;
  line-height: 2.2em;
  padding: 0.71em 1.42em 0.71em 1.42em;
  border: 1px solid #e1e9ef;
  display: none;
  position: fixed;
  bottom: 0;
  width: 90%;
  left: 50%;
  margin-left: -45%;
}
.zs-app-message button {
  .margin(0,0,0,4.26);
  line-height: 1.25em;
}
.zs-app-message:before,
.zs-app-message:after {
  content: "";
  display: table;
}
.zs-app-message:after {
  clear: both;
}
.zs-app-message .zs-icon.zs-icon-rejected-approval {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -0.57142857em;
  margin-right: 1.42em;
}
/*** Old Launchbar styles ***/
#lbr {
  background: #575757 url("./launch-bar.gif") 0 -9px repeat-x;
  font-size: 8pt;
  font-family: Tahoma, Helvetica, Sans-Serif;
  height: 1.4rem;
  line-height:  2.09em;
}
div#lbr * {
  font-size: 8pt;
  font-family: Tahoma, Helvetica, Sans-Serif;
}
#lbr #lbar-apps {
  float: left;
  font-weight: bold;
}
#lbr #lbar-app-more {
  position: relative;
  z-index: 1500;
}
#lbr #lbar-logout {
  float: right;
}
#lbr #lbar-user {
  float: right;
  position: relative;
  z-index: 1500;
}
#lbr #lbar-user .lbar-menu-popup {
  right: -1px;
  min-width: 10em;
}
#lbr ul,
#lbr ul li,
#lbr a.lbar-link,
#lbr a.lbar-menu-dropdown {
  display: inline-block;
  height: 100%;
  /* For IE */
}
#lbr a {
  color: @zs-button-border-color;
}
#lbr ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#lbr a.lbar-link {
  text-decoration: none;
  .padding(0,1.81,0,1.81);
}
#lbr a.lbar-link:hover {
  color: white;
}
#lbr .lbar-selected {
  background: url("./launch-bar.gif") no-repeat;
  background-position: 50% -5.81em;
}
#lbr .lbar-selected a {
  color: white;
}
#lbr a.lbar-menu-dropdown {
  text-decoration: none;
  .padding(0,4.72,0,3.8);
  background: url("./launch-bar.gif") 100% -9.374em no-repeat;
}
#lbr .lbar-menu-on a.lbar-menu-dropdown {
  color: white;
  background-position: 100% -12.374em;
}
#lbr .lbar-menu-off {
  border-left: solid 0.09em transparent;
  border-right: solid 0.09em transparent;
}
#lbr .lbar-menu-on {
  background: url("./launch-bar.gif") 0 -3.09em repeat-x;
  border-left: solid 0.09em #909090;
  border-right: solid 0.09em #909090;
}
#lbr .lbar-menu-on .lbar-menu-popup {
  display: block;
}
#lbr .lbar-menu-popup {
  display: none;
  position: absolute;
  top: 2.09em;
  left: -0.09em;
  background-color: #626262;
  border-left: solid 0.09em #909090;
  border-right: solid 0.09em #909090;
  border-bottom: solid 0.09em #909090;
  font-weight: bold;
  line-height: 2.72em;
}
#lbr .lbar-menu-popup a {
  display: block;
  white-space: nowrap;
}
#lbr .lbar-menu-popup li {
  width: 100%;
}
#lbr .lbar-menu-popup ul {
  width: 100%;
}
#lbr #lbar-notifications {
  position: relative;
  float: left;
  background: #cc0000  url("./launch-bar-notifications.gif") 0.18em -0.36em no-repeat;
  border-right: none;
  color: white;
  font-weight: bold;
  z-index: 10000;
}
#lbr #lbar-notifications a.lbar-menu-dropdown {
  color: white;
  background-position: 100% -12.4em;
  padding-left: 3em;
}
#lbr #lbar-notifications .lbar-menu-popup {
  background-color: white;
  font-weight: normal;
  border: solid 0.37em #cc0000;
  -webkit-box-shadow: 0.093em 0.093em 0.281em #000000 inset, 0.093em 0.093em 0.18em rgba(40, 40, 40, 0.6), 0.18em 0.18em 0.468em #575757;
}
#lbr #lbar-notifications .lbar-menu-popup em {
  font-weight: bold;
  font-style: normal;
}
#lbr #lbar-notifications .lbar-menu-popup a.note-link {
  text-decoration: none;
  color: #3577C9;
}
#lbr #lbar-notifications .lbar-menu-popup a.note-link:hover {
  text-decoration: underline;
}
#lbr #lbar-notifications.lbar-menu-on {
  border-left: solid 0.093em #cc0000;
}
#lbr #lbar-notifications .lbar-link {
  color: black;
}
#lbr #lbar-not-prod {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #7e7e7e;
  color: white;
  font-weight: bold;
  height: 100%;
  .padding(0,0.93,0,0.93);
  .margin(0,0.93,0,0);
}
#lbr #lbar-user .user-name {
  font-weight: bold;
  .padding(0,0,0,1.31);
}
.zs-submenu > a:first-child + a,
.zs-mobile-header .zs-top-navigation > ul > li.zs-submenu > a:first-child + a,
.zs-user-bar > div > a:first-child + a {
  position: absolute;
  top: 50%;
  margin-top: -0.57142857em;
  right: 0;
}
.zs-submenu.zs-selected:before,
.zs-mobile-header .zs-top-navigation > ul > li.zs-submenu.zs-selected:before,
.zs-user-bar > div.zs-selected:before {
  content: '';
  background: transparent;
  border-radius: 0.3em;
  border: 0.093em solid #e1e9ef;
  box-shadow: 0 0.281em 0.56em #dee3e3;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  top: 0px;
  position: absolute;
  right: -0.093em;
  bottom: -0.093em;
  left: -0.093em;
  z-index: 0;
}
.zs-submenu.zs-selected:after,
.zs-mobile-header .zs-top-navigation > ul > li.zs-submenu.zs-selected:after,
.zs-user-bar > div.zs-selected:after {
  margin-top: -0.093em;
  left: 0;
  top: 100%;
  content: '';
  right: 0;
  height: 0.187em;
  background: #ffffff;
  display: inline-block;
  position: absolute;
  z-index: 2;
}
.zs-submenu > nav,
.zs-mobile-header .zs-top-navigation > ul > li.zs-submenu > nav,
.zs-user-bar > div > nav {
  position: absolute;
  right: -0.093em;
  top: 100%;
  border-top-right-radius: 0;
  z-index: 1;
  min-width: 120%;
}
.zs-submenu > nav > ul > li,
.zs-mobile-header .zs-top-navigation > ul > li.zs-submenu > nav > ul > li,
.zs-user-bar > div > nav > ul > li {
  display: block;
  position: relative;
}
.zs-submenu > nav > ul > li.zs-active,
.zs-mobile-header .zs-top-navigation > ul > li.zs-submenu > nav > ul > li.zs-active,
.zs-user-bar > div > nav > ul > li.zs-active {
  background: #e5eaef;
}
.zs-submenu.zs-selected.zs-to-left > nav,
.zs-mobile-header .zs-top-navigation > ul > li.zs-submenu.zs-selected.zs-to-left > nav,
.zs-user-bar > div.zs-selected.zs-to-left > nav {
  left: -0.093em;
  right: auto;
  border-top-right-radius: 0.3em;
  border-top-left-radius: 0;
}
.zs-user-bar .zs-icon-large {
  display: none;
}
@media (min-width: 0) {
  .zs-user-bar label {
    display: none;
  }
  .zs-user-bar .zs-icon {
    display: none;
  }
  .zs-user-bar .zs-icon.zs-icon-large {
    display: inline;
  }
}
@media (min-width: 72.09em) {
  .zs-user-bar [role="profile"] label {
    display: inline-block;
    max-width: 7em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media (min-width: 96.090em) {
  .zs-user-bar .zs-icon {
    display: inline;
  }
  .zs-user-bar .zs-icon.zs-icon-large {
    display: none;
  }
  .zs-user-bar label,
  .zs-user-bar [role="profile"] label {
    display: inline;
  }
}
