/* You can add global styles to this file, and also import other style files */
/* ZSUI application styles bundle */
@import '../../node_modules/zsui/src/core/core.less';
@import '../../node_modules/zsui/src/utils/display.less';
@import '../../node_modules/zsui/src/accordion/accordion.less';
@import '../app/revo-core/zsui-component-extensions/accordion.less';
@import '../../node_modules/zsui/src/utils/color.less';
@import '../../node_modules/zsui/src/utils/position.less';
@import '../../node_modules/zsui/src/layout/layoutGrid.less';
@import '../../node_modules/zsui/src/layout/responsiveGrid.less';
@import '../../node_modules/zsui/src/utils/size.less';
@import '../../node_modules/zsui/src/utils/border.less';
@import '../../node_modules/zsui/src/utils/space.less';
@import '../../node_modules/zsui/src/utils/font.less';
@import '../../node_modules/zsui/src/utils/shadow.less';
@import '../../node_modules/zsui/src/utils/zIndex.less';
@import '../../node_modules/zsui/src/utils/flex.less';
@import '../../node_modules/zsui/src/loader/loader.less';
// Button component
// import all button deps
// Button from default theme
// Button from Master theme

//CheckboxRadio
@import '../../node_modules/zsui/src/checkboxRadio/checkboxRadio.less';

// Field component
@import '../../node_modules/zsui/src/field/field.less';
@import '../../node_modules/zsui/src/actionField/actionField.less';
@import '../../node_modules/zsui/src/validation/validation.less';
@import '../../node_modules/zsui/src/field/field.less';

// Select component
@import '../../node_modules/zsui/src/select/select.less';

//Table component
@import '../../node_modules/zsui/src/table/table.less';

// Toggle
@import '../../node_modules/zsui/src/toggle/toggle.less';

//Topbar
@import '../assets/zsui/zsui-auth/src/user/user.less';

// Tooltip
@import '../../node_modules/zsui/src/tooltip/tooltip.less';

//timePicker component
@import '../../node_modules/zsui/src/timePicker/timePicker.less';

// Pop-up Menu
@import '../../node_modules/zsui/src/menu/popupMenu.less';

// DatePicker Component
@import '../../node_modules/zsui/src/datePicker/datePicker.less';

// Card  Component
@import '../../node_modules/zsui/src/card/card.less';

//Button
@import '../../node_modules/zsui/src/button/button.less';
@import '../../node_modules/zsui/src/utils/border.less';

//ToggleTabs
@import '../../node_modules/zsui/src/toggleTabs/toggleTabs.less';

// Carousel
@import '../../node_modules/zsui/src/carousel/carousel.less';
@import '../app/revo-core/zsui-component-extensions/carousel.less';

// Configuration
@import '../../node_modules/zsui/src/configuration/config.less';

// Drawer
@import '../../node_modules/zsui/src/drawer/drawer.less';

//tag component
@import '../../node_modules/zsui/src/tag/tag.less';
//Card
@import '../../node_modules/zsui/src/card/card.less';
//accordian
@import '../../node_modules/zsui/src/accordion/accordion.less';
// Tabs
@import '../../node_modules/zsui/src/tab/tab.less';

//Toggle Tabs
@import '../../node_modules/zsui/src/toggleTabs/toggleTabs.less';
@import '../../node_modules/zsui/src/utils/border.less';
// Badge
@import '../../node_modules/zsui/src/badge/badge.less';

//timePicker component
@import '../../node_modules/zsui/src/timePicker/timePicker.less';

//progressBar component
@import '../../node_modules/zsui/src/progressBar/progressBar.less';

@import '../../node_modules/zsui/src/toolbar/toolbar.less';
@import '../../node_modules/zsui/src/primaryNavigation/primaryNavigation.less';

// Pagination
@import '../../node_modules/zsui/src/pagination/pagination.less';

// draggable component
@import '../../node_modules/zsui/src/dragDrop/dragDrop.less';

// Dialog component
@import '../../node_modules/zsui/src/dialog/dialog.less';

// Stepper component
@import '../../node_modules/zsui/src/stepper/stepper.less';

// Wizard Component
@import '../../node_modules/zsui/src/wizard/wizard.less';

// Your custom settings
@import './config.less';

// -------- import official less file -----------
@import '../../node_modules/ng-zorro-antd/ng-zorro-antd.less';

// -------- override less variables -----------
@primary-color: #ed8b00;

body ::selection {
  background-color: #27a6a4;
}

::-webkit-scrollbar
{
	width: 18px !important;
  height: 18px !important;
	background-color: #F1F1F1;
}

::-webkit-scrollbar-thumb
{
	border-radius: 1px;
	background-color: #C1C1C1;
}

::-webkit-scrollbar-thumb:hover
{
	background-color: #A8A8A8;
}

::-webkit-scrollbar-thumb:horizontal
{
	border-top: 2px solid #F1F1F1;
  border-bottom: 2px solid #F1F1F1;
}

::-webkit-scrollbar-thumb:vertical
{
	border-left: 2px solid #F1F1F1;
  border-right: 2px solid #F1F1F1;
}

::-webkit-scrollbar-thumb:active
{
	background-color: #787878;
}
/*
::-webkit-scrollbar-track-piece
{

}
*/

::-webkit-scrollbar-track
{
	background-color: #F1F1F1;
}


/* Buttons */
::-webkit-scrollbar-button
{
  background-color:  #F1F1F1;
  background-repeat: no-repeat;
  background-size: 7px;
  background-position: center;
  height: 30px;
  width: 30px;
}

::-webkit-scrollbar-button:disabled
{
  /* Why is this not red??? */
  background-color: #d2d2d2;
}

::-webkit-scrollbar-button:hover
{
  background-color: #d2d2d2;
}

::-webkit-scrollbar-button:active
{
  background-color: #787878;
}

/* Up */
::-webkit-scrollbar-button:vertical:decrement
{
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23505050'><polygon points='50,30 100,80 0,80'/></svg>");
}

::-webkit-scrollbar-button:vertical:decrement:active
{
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23FFFFFF'><polygon points='50,30 100,80 0,80'/></svg>");
}

::-webkit-scrollbar-button:vertical:decrement:hover
{
  border-color: transparent transparent #505050 transparent;
}


/* Down */
::-webkit-scrollbar-button:vertical:increment
{
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23505050'><polygon points='0,15 100,15 50,75'/></svg>");
}

::-webkit-scrollbar-button:vertical:increment:hover
{
  border-color: #505050 transparent transparent transparent;
}


/* Left */
::-webkit-scrollbar-button:horizontal:decrement
{
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23505050'><polygon points='15,50 75,100 75,0'/></svg>");
}

::-webkit-scrollbar-button:horizontal:decrement:hover
{
  border-color: transparent #505050 transparent transparent;
}


/* Right */
::-webkit-scrollbar-button:horizontal:increment
{
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23505050'><polygon points='15,0 15,100 75,50'/></svg>");
}

::-webkit-scrollbar-button:horizontal:increment:hover
{
  border-color: transparent transparent transparent #505050;
}

